.card{
 width: 100%;
 position: relative;
 overflow: inherit !important;
}
.actions{
 z-index: 2;
 display: flex;
 justify-content: flex-end;
 flex-wrap: wrap;
}