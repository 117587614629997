
.desktop{
  display: flex;
  justify-content: center;
.preview{
  max-width: 800px;
  background: #1F1F25;
  border-radius: 32px;
  border: 1px solid #31313C;
  padding: 32px 32px;
}
}
.mobile{
  display: flex;
  justify-content: center;
  .preview{
    background: #16161B;
    width: 420px;
    padding: 16px;
  }
}