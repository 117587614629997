.root{
  position: relative;
  padding: 8px;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 64px);
  overflow-y: auto;
}
.gridList{
  margin: 0;

}
.tileBar{
  background: linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%);
}
.activeItem{
  border: 3px solid red
}
.dragActive{

}
.dropZone{
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  bottom: 0px;
  right: 0px;
  background: rgba(0,0,0, 0.5);
  color: white;
}
.dropZoneWrapper{
  position: absolute;
  top: 15px;
  bottom: 15px;
  left: 15px;
  right: 15px;
  border: 3px white dashed;
  display: flex;
  align-items: center;
  justify-content: center;
}
.dropZoneText{

}

.uploading{
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 10;
  bottom: 0px;
  right: 0px;
  background: rgba(0,0,0, 0.5);
}

.dialogTitle{
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
}
.dialogTitleActions{
  display: flex;
  justify-content: flex-end;
}