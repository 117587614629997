.root{
  position: relative;
  padding: 8px;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 64px);
  overflow-y: auto;
}
.gridList{
  margin: 0;

}
.tileBar{
  background: linear-gradient(to top, rgba(0,0,0,0.8) 0%,rgba(0,0,0,0.4) 70%,rgba(0,0,0,0) 100%);
}
.activeItem{
  border: 3px solid red
}
.settings{
  position: absolute;
  right: 10px;
  top: 10px;
}