.root{
}
.imageView{
  box-shadow: inset 0 0 5px 0 rgb(0 0 0 / 10%);
  min-height: 60vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  width: 100%;
  flex-direction: column
}
.imageWrapper{
  position: relative
}
.image{
  position: relative
}
.imageToolbar{
  display: flex;
  align-items: flex-end
}
.comment{
  max-width: 20em;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tab{
  max-width: 40em;
  display: block;
}